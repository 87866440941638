import { Alert, Button, Icon, Loading, Modal, Typography } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { addToast, clsx } from "@/utils";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useState } from "react";
import { addOnItemSelector } from "@/redux/slices/addOn/selectors";
import { renderAddOnCost, renderAddOnIcon, renderAddOnLockIcon } from "../helpers";
import { AddOnRedirectionModal } from "./AddOnRedirectionModal";
import { addOnsLinks, addOnsModalDescription } from "../fixtures";
import CompanyManagerPreview from "@/assets/images/add-ons/company-manager-preview.png";
import TripReportingPreview from "@/assets/images/add-ons/trip-reporting-preview.png";
import PrivateNetworkPreview from "@/assets/images/add-ons/private-network-preview.png";
import RatingManagerPreview from "@/assets/images/add-ons/rating-manager-preview.png";
import SMSJobRatingPreview from "@/assets/images/add-ons/sms-job-rating-preview.png";
import { AddOnDisableModal } from "./AddOnDisableModal";
import { AddOnDisableErrorModal } from "./AddOnDisableErrorModal";
import { useToggleAddOn } from "../hooks/useToggleAddOn";
import { RawErrorResponse } from "@/redux/types";
import { useNavigate } from "react-router-dom";

interface AddOnModalProps {
  identifier: string;
  open: boolean;
  onClose: () => void;
  isAdmin: boolean;
}

export const AddOnModal = ({ identifier, open, onClose, isAdmin }: AddOnModalProps) => {
  const navigate = useNavigate();
  const item = useAppSelector(addOnItemSelector(identifier));
  const [modal, setModal] = useState<"disable" | "disableError" | "disableErrorCompanyManager" | "redirection" | undefined>(undefined);
  const [action, setAction] = useState<"enable" | "disable">("enable");
  const hasLink = Object.keys(addOnsLinks).includes(identifier);
  const name = item ? item.name : "";

  const { toggleAddOn, isLoading } = useToggleAddOn({ 
    onEnableSuccess: () => {
      if (hasLink) setTimeout(() => navigate(`/${addOnsLinks[identifier]}`), 2000);
      else addToast("success", `${name} has been successfully enabled.`);
    }, 
    onDisableSuccess: () => {
      if (hasLink) setTimeout(() => setModal(undefined), 2000);
      addToast("info", `${name} has been disabled.`);
      onClose();
    }, 
    onEnableError: (e: RawErrorResponse) => {
      getErrorMessages(e).forEach((message) => addToast("danger", message));
      setModal(undefined);
    },
    onDisableError: (e: RawErrorResponse) => {
      if (e.data.error === "ADD-ON-COMPANY-DEACTIVATION-ERROR") setModal("disableErrorCompanyManager");
      else setModal("disableError");
    },
    shouldUpdateOperator: true,
  });

  const handleEnable = () => {
    if (hasLink) {
      setAction("enable");
      setModal("redirection");
    }
    
    toggleAddOn(identifier, true);
  };

  const handleDisable = () => {
    if (hasLink) {
      setAction("disable");
      setModal("redirection");
    }

    toggleAddOn(identifier, false);
  };

  const renderHeader = () => (
    <div className="flex flex-row items-center">
      <Typography variant="title" className="flex-1">
        Add-ons
      </Typography>
      <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
    </div>
  );

  const renderButton = () => {
    if (item && item.available) {
      if (isAdmin) {
        if (item.enabled) return <Button variant="secondary" size="lg" startIcon="MinusCircle" iconVariant="Bold" iconClassName="text-danger" className="w-full mt-8" onClick={() => setModal("disable")}>Disable {item.name}</Button>;
        else return (
          <div className="relative mt-8">
            {isLoading && <Loading />}
            <Button variant="primary" size="lg" className="w-full" onClick={handleEnable}>Enable {item.name}</Button>
          </div>
        );
      } else return <Alert type="info" message={`Request access from your admin for ${name.toLowerCase()}.`} className="w-full mt-4" />;
    }
  };

  const renderPreviewImage = () => {
    let image = null;
    switch (identifier) {
      case "company-manager":
        image = CompanyManagerPreview;
        break;
      case "trip-reporting":
        image = TripReportingPreview;
        break;
      case "private-network":
        image = PrivateNetworkPreview;
        break;
      case "rating-manager":
        image = RatingManagerPreview;
        break;
      case "sms-job-rating":
        image = SMSJobRatingPreview;
        break;
      default: 
        return image;
    }

    return (
      <div className={clsx("relative bg-neutral-gray rounded-lg w-full mt-4 px-[25px] pt-[30px]", { "px-[110px] pt-[50px]": identifier === "sms-job-rating" })}>
        {renderAddOnLockIcon(item?.enabled)}
        <img src={image} />
      </div>
    );
  };

  const renderModals = () => (item && (
    <>
      <AddOnRedirectionModal name={item.name} isActivating={action === "enable"} open={modal === "redirection"} />
      <AddOnDisableModal id={item.identifier} name={item.name} open={modal === "disable"} onClose={() => setModal(undefined)} onDisable={handleDisable} isLoading={isLoading} />
      <AddOnDisableErrorModal 
        open={modal === "disableError" || modal === "disableErrorCompanyManager"} 
        onClose={() => setModal(undefined)} 
        id={modal === "disableErrorCompanyManager" ? item.identifier : undefined}
      />
    </>
  ));
  
  return (
    <Modal
      open={open}
      onClose={onClose}
      className="w-[85vw] md:w-[550px] lg:w-[550px]"
    >
      {renderHeader()}
      <div className="mt-3">
        <div className="flex flex-col md:flex-row md:items-center bg-neutral-surface-gray rounded-lg gap-y-2.5 py-2.5 pl-2.5 pr-5 mt-3">
          <div className="flex flex-1 items-center flex-row gap-x-4">
            <div className="flex items-center justify-center bg-primary-dark rounded-md p-2.5">
              {renderAddOnIcon(identifier)}
            </div>
            <Typography variant="h3">{name}</Typography>
          </div>
          {renderAddOnCost(item)}
        </div> 
        {renderPreviewImage()}
        <Typography variant="paragraph" className="mt-4">{addOnsModalDescription[identifier]}</Typography>
        {renderButton()}
      </div>
      {renderModals()}
    </Modal>
  );
};
