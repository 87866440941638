import { Container } from "@/components/atoms";
import { PageHeader } from "@/components/organisms";
import { AddOnsPanel } from "@/components/organisms/AddOns/AddOnsPanel";

export const AddOns = () => (
  <div className="mb-12 flex flex-1 flex-col">
    <Container className="w-full">
      <PageHeader skipCrumbs={1} title="Add Ons Center" />
      <AddOnsPanel />
    </Container>
  </div>
);
