import { useOperator } from "@/hooks";
import { independentModals } from "./fixtures";
import { AddOnModal } from "./modals/AddOnModal";
import { FlightTrackingModal } from "./FlightTracking/FlightTrackingModal";
import { InvoicingModal } from "./Invoicing/InvoicingModal";
import { OffloadingModal } from "./Offloading/OffloadingModal";

interface AddOnsModalsProps extends React.HTMLAttributes<HTMLDivElement> {
  identifier: string;
  onClose: () => void;
}

export const AddOnsModals = ({ identifier, onClose, className, ...props }: AddOnsModalsProps) => {
  const { roles } = useOperator();
  const isAdmin = roles.includes("Fa_Access") || roles.includes("Owner") || roles.includes("Admin");

  return (
    <div className={className} {...props}>
      <FlightTrackingModal 
        open={identifier === "flight-tracking"} 
        onClose={onClose} 
        isAdmin={isAdmin}
      />
      <OffloadingModal
        open={identifier === "offloading"} 
        onClose={onClose} 
        isAdmin={isAdmin}
      />
      <InvoicingModal
        open={identifier === "invoicing"} 
        onClose={onClose} 
        isAdmin={isAdmin}
      />
      <AddOnModal
        identifier={identifier}
        open={!independentModals.includes(identifier)}
        onClose={onClose} 
        isAdmin={isAdmin}
      />
    </div>
  );
};
