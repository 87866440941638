import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithProvider } from "@/redux/baseQuery";
import { transformAddOns, transformOffloadingConfiguration, transformInvoicingConfiguration } from "./helpers";
import { GetAddOnResponse, ToggleAddOnParams, GetOffloadingConfigurationResponse, UpdateOffloadingConfigurationParams, GetInvoicingConfigurationResponse, UpdateInvoicingConfigurationParams, GetAddOnsParams } from "./types";
import { createSearchParams } from "react-router-dom";
import { invalidateOn } from "@/helpers/reduxHelpers";

export const addOnApi = createApi({
  reducerPath: "addOnApi",
  baseQuery: baseQueryWithProvider("add-on"),
  endpoints: (builder) => ({
    getPremiumAddOns: builder.query<GetAddOnResponse, void>({
      query: () => "?premium=1",
      providesTags: ["AddOns"],
      transformResponse: transformAddOns,
    }),
    getAddOns: builder.query<GetAddOnResponse, GetAddOnsParams>({
      query: (params) => `?premium=0&${createSearchParams(params).toString()}`,
      providesTags: ["AddOns"],
      transformResponse: transformAddOns,
    }),
    getFeaturedAddOns: builder.query<GetAddOnResponse, void>({
      query: () => "?featured=true",
      providesTags: ["AddOns"],
      transformResponse: transformAddOns,
    }),
    toggleAddOn: builder.mutation<void, ToggleAddOnParams>({
      query: ({ id, enabled }) => ({
        url: `/${id}`,
        method: "POST",
        body: { enabled },
      }),
      invalidatesTags: invalidateOn({ success: ["AddOns"] }),
    }),
    getOffloadingConfiguration: builder.query<GetOffloadingConfigurationResponse, void>({
      query: () => "/offloading/configuration",
      transformResponse: transformOffloadingConfiguration,
    }),
    updateOffloadingConfiguration: builder.mutation<void, UpdateOffloadingConfigurationParams>({
      query: (body) => ({
        url: "/offloading/configuration",
        method: "POST",
        body,
      }),
    }),
    getInvoicingConfiguration: builder.query<GetInvoicingConfigurationResponse, void>({
      query: () => "/invoicing/configuration",
      transformResponse: transformInvoicingConfiguration,
    }),
    updateInvoicingConfiguration: builder.mutation<void, UpdateInvoicingConfigurationParams>({
      query: (body) => ({
        url: "/invoicing/configuration",
        method: "POST",
        body,
      }),
    }),
  }),
  tagTypes: ["AddOns"],
});

export const { useGetPremiumAddOnsQuery, useGetAddOnsQuery, useGetFeaturedAddOnsQuery, useToggleAddOnMutation, useLazyGetOffloadingConfigurationQuery, useUpdateOffloadingConfigurationMutation, useLazyGetInvoicingConfigurationQuery, useUpdateInvoicingConfigurationMutation } = addOnApi;
