import { isEmpty } from "ramda";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Avatar, Divider, Icon, IconButton, SidePanel, Typography } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { useAppDispatch, useAppResetApi, useAppSelector } from "@/redux/hooks";
import { logOut } from "@/redux/slices/auth/authSlice";
import { newNotificationsSelector } from "@/redux/slices/notification/selectors";
import { getAssetUrl } from "@/utils";
import { SidebarNavItem } from "../Sidebar";
import { SidebarNav } from "../Sidebar/SidebarNav";

interface HeaderSidePanelProps {
  open: boolean;
  onClose: () => void;
  viewTransportProviderCode: () => void;
  viewNotifications: () => void;
}

export const HeaderSidePanel = ({ open, onClose, viewTransportProviderCode, viewNotifications }: HeaderSidePanelProps) => {
  const resetApi = useAppResetApi();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const profile = useProfile();
  const { avatar, displayName } = profile;
  const avatarUrl = getAssetUrl(avatar, { height: 120, width: 120, fit: "cover" });
  const newNotifications = useAppSelector(newNotificationsSelector);

  useEffect(() => {
    if (open) onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleLogout = () => {
    dispatch(logOut());
    resetApi();
  };

  return (
    <SidePanel open={open} onClose={onClose} width={300} focused>
      <div className="flex h-[72px] items-center justify-between pl-4 pr-6">
        <IconButton iconName="ArrowRight" onClick={onClose} variant="tertiary" iconSize="lg" className="hover:bg-transparent" />
      </div>
      <nav className="flex h-full flex-col">
        <div className="no-scrollbar flex-1 overflow-auto">
          <div className="flex flex-1 flex-col">
            <SidebarNav ignoreSidebarOpen />
            <Divider className="mx-5 my-5" />
            <div className="flex flex-col px-5 pb-2.5">
              <Typography variant="action" className="mb-3 text-neutral-dark-gray">
                Profile and Settings
              </Typography>
              <div className="flex flex-col">
                <Link
                  to="profile"
                  className="flex cursor-pointer items-center rounded-md p-2.5 hover:bg-neutral-gray hover:text-neutral-black"
                >
                  <span className="flex w-full items-center gap-x-3 text-neutral-black">
                    <Avatar src={avatarUrl} alt={displayName} size="xs" />
                    <Typography variant="action">{displayName}</Typography>
                  </span>
                </Link>
              </div>
              <div className="flex flex-col">
                <div
                  className="flex cursor-pointer items-center rounded-md p-2.5 hover:bg-neutral-gray hover:text-neutral-black"
                  onClick={viewTransportProviderCode}
                >
                  <span className="flex w-full gap-x-3 text-neutral-black">
                    <Icon name="Text" className="flex-shrink-0" aria-hidden="true" variant="Bold" />
                    <Typography variant="action">Transport Provider Code</Typography>
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <div
                  className="flex cursor-pointer items-center rounded-md p-2.5 hover:bg-neutral-gray hover:text-neutral-black"
                  onClick={viewNotifications}
                >
                  <span className="flex w-full gap-x-3 text-neutral-black">
                    <div className="relative flex-shrink-0">
                      <Icon name="Notification" aria-hidden="true" variant="Bold" />
                      {!isEmpty(newNotifications) && (
                        <svg className="absolute bottom-4 left-4 h-3 w-3 text-danger" fill="currentColor" viewBox="0 0 8 8">
                          <circle cx={4} cy={4} r={3} />
                        </svg>
                      )}
                    </div>
                    <Typography variant="action">Notification</Typography>
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <div
                  className="flex cursor-pointer items-center rounded-md p-2.5 hover:bg-neutral-gray hover:text-neutral-black"
                  onClick={handleLogout}
                >
                  <span className="flex w-full gap-x-3 text-neutral-black">
                    <Icon name="LogoutCurve" className="flex-shrink-0 rotate-180" aria-hidden="true" variant="Bold" />
                    <Typography variant="action">Logout</Typography>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-[30px] border-t border-neutral-mid-gray bg-neutral-surface-gray px-5 pb-5 pt-2.5">
          <SidebarNavItem
            item={{
              name: "Add Ons",
              to: "/add-ons",
              isCustomIcon: true,
              icon: "rocket",
            }}
            sidebarOpen={true}
            className="bg-neutral-gray text-neutral-black"
          />
          <Typography variant="small" className="text-center !text-[10px] text-neutral-dark-gray">
            © Copyright {new Date().getFullYear()} - Powered by RideMinder
          </Typography>
        </div>
      </nav>
    </SidePanel>
  );
};
